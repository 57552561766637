import * as React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"

const Index = () => (
    <Layout>
        <SEO title="Contact" />
        <section className="bg-white-100">
            <div className="container mx-auto pt-10 space-y-4">
                <div className="grid lg:grid-cols-2 md:grid-cols-2">
                    <div className="max-w-lg">
                        <h6 className="text-body-muted text-lg font-semibold">CONTACT US</h6>
                        <h2 className="text-heading-dark text-5.5xl font-bold">Let’s talk.</h2>
                        <p className="text-lg">Contact our team, we are here to help. You can schedule a free demo to see clearly the features of Handdy and understand how we can help you to increase your team’s productivity, and help you succeed.</p>
                        <div className="flex space-x-4 lg:mt-8 md:mt-4 mt-2">
                            <a className="py-3 px-5 rounded-xl flex flex-col bg-danger-dark text-white" href="tel:+1 412 267 1529">
                                <span>Call us (USA)</span>
                                <span>+1 412 267 1529</span>
                            </a>
                            <a className="py-3 px-5 flex flex-col rounded-xl bg-danger-dark text-white" href="tel:+91 93459 24122">
                                <span>Call us (India)</span>
                                <span>+91 93459 24122</span>
                            </a>
                        </div>
                        <img className="lg:mt-16 md:mt-12 mt-8" src="/companies/companies.png"></img>
                    </div>
                    <form className="shadow-outer p-2 lg:p-8 md:p-4  rounded-2xl bg-white grid gap-5 content-start">
                        <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-5">
                            <div className="flex space-y-2 flex-col">
                                <label className="text-sm font-semibold text-heading-dark">Name<span className="text-danger-dark">*</span></label>
                                <input required className="p-4 placeholder-body-light border-primary-border rounded-xl border focus:outline-none focus:shadow-md" placeholder="Enter your name" type="text"></input>
                            </div>
                            <div className="flex space-y-2 flex-col">
                                <label className="text-sm font-semibold text-heading-dark">Work email<span className="text-danger-dark">*</span></label>
                                <input required className="p-4 placeholder-body-light border-primary-border rounded-xl border focus:outline-none focus:shadow-md" placeholder="Enter your work email" type="text"></input>
                            </div>
                        </div>
                        <div className="flex space-y-2 flex-col ">
                            <label className="text-sm font-semibold text-heading-dark">Phone:<span className="text-danger-dark">*</span></label>
                            <input required className="p-4 placeholder-body-light border-primary-border rounded-xl border focus:outline-none focus:shadow-md" type="tel"></input>
                        </div>
                        <div className="flex space-y-2 flex-col">
                            <label className="text-sm font-semibold text-heading-dark">Message</label>
                            <textarea rows="4" className="p-4 placeholder-body-light border-primary-border rounded-xl border focus:outline-none focus:shadow-md" placeholder="Anything you want to add?" type="text"></textarea>
                        </div>
                        <button type="submit" className="font-medium focus:outline-none text-white bg-primary text-sm leading-3 py-5 px-6 rounded-md bg-blue-500 hover:bg-blue-600 hover:shadow-lg">Send your message -&gt;</button>
                    </form>
                </div>
            </div>
        </section>
        <section>
            <div className="container mx-auto space-y-4">
                <div className="lg:py-17 md:py-12 sm:py-8 py-4 grid lg:gap-17 md:gap-12 sm:gap-8 gap-4">
                    <h2 className="font-bold text-heading-dark text-2xl lg:text-5.5xl md:text-4xl sm:text-3xl lg:leading-14">
                        Our offices
                    </h2>
                    <div className="adress-wrapper">
                        <div className="border-surface-primary card- rounded-t-2.5xl shadow-2xl">
                            <img src="/uk.png"></img>
                            <div className="lg:p-8 md:p-4 p-2 space-y-2">
                                <h2 className="text-heading-dark font-semibold lg:text-3xl md:text-2xl sm:text-xl text-lg">UK Office</h2>
                                <address className="not-italic text-lg space-y-2">
                                    17 King Edwards Road<br />
                                    Ruislip, London, UK<br />
                                    HA4 7AE<br />
                                    <a href="tel:+44 (0)20 7993 2949">Phone: +44 (0)20 7993 2949</a>
                                    <a href="#" className="text-arrow-right block">Open in Maps</a>
                                </address>
                            </div>
                        </div>
                        <div className="border-surface-primary card- rounded-t-2.5xl shadow-2xl">
                            <img src="/usa.png"></img>
                            <div className="lg:p-8 md:p-4 p-2 space-y-2">
                                <h2 className="text-heading-dark font-semibold lg:text-3xl md:text-2xl sm:text-xl text-lg">USA Office</h2>
                                <address className="not-italic text-lg space-y-2">
                                    7500 Brooktree Road<br />
                                    Wexford, PA 15090<br />
                                    <a href="tel:+1 412-267-1529">Phone: +1 412-267-1529</a>
                                    <a href="#" className="text-arrow-right block">Open in Maps</a>
                                </address>
                            </div>
                        </div>
                        <div className="border-surface-primary card- rounded-t-2.5xl shadow-2xl">
                            <img src="/india.png"></img>
                            <div className="lg:p-8 md:p-4 p-2 space-y-2">
                                <h2 className="text-heading-dark font-semibold lg:text-3xl md:text-2xl sm:text-xl text-lg">India Office</h2>
                                <address className="not-italic text-lg space-y-2">
                                    First floor, Kanapathy Towers<br />
                                    Opp.BSNL exchange, Ganapathy<br />
                                    Coimbatore - 641 006, India<br />
                                    <a href="tel:+91 93459 24122">Phone: +91 93459 24122</a>
                                    <a href="#" className="text-arrow-right block">Open in Maps</a>
                                </address>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
)

export default Index
